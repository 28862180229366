import {ExperimentNames} from '@wix/wix-events-commons-statics'
import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useExperiments} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React, {useEffect} from 'react'
import {useWidgetState} from '../../../hooks/state-provider'
import {useEventsList} from '../../../hooks/use-events-list'
import {getCompId} from '../../../selectors/component'
import {focusFirstNewEvent} from '../../../utils/a11y'
import {LoadMoreButton} from '../../load-more-button'
import {MainListTitle} from '../../main-list-title'
import {SideBySideItem} from '../side-by-side-item'
import {SideBySideListProps} from './interfaces'
import s from './side-by-side-list.scss'
import {Utils} from './utils'

export const SideBySideList = ({width}: SideBySideListProps) => {
  const {events, hasMore, moreLoading, pageLimit} = useEventsList()
  const {experiments} = useExperiments()

  const utils = new Utils()
  const compId = useWidgetState(getCompId)
  const eventsCount = events.length
  const loadMoreVisible = hasMore || moreLoading
  const widgetHeightSettingsEnabled = experiments.enabled(ExperimentNames.WidgetHeightSettings)
  const widgetListTitleUpdatesEnabled = experiments.enabled(ExperimentNames.WidgetListTitleUpdates)

  useEffect(() => {
    focusFirstNewEvent({eventsCount, pageLimit, hasMore, compId})
  }, [eventsCount])

  return (
    <div
      className={classNames(s.container, {
        [s.padding]: widgetListTitleUpdatesEnabled,
        [s.border]: experiments.enabled(ExperimentNames.MobileSettingsPanelRevamp),
      })}
    >
      <MainListTitle />
      <div className={classNames(s.items, {[s.padding]: !widgetListTitleUpdatesEnabled})}>
        <ul data-hook={DH.sideBySideItems} className={s.list}>
          {events.map((event: ExtendedEvent, index: number) => (
            <li
              key={event.id}
              className={classNames(s.item, utils.itemsHeightClass(width), {
                [s.widgetHeightSettings]: widgetHeightSettingsEnabled,
              })}
              data-hook={DH.sideBySideItem}
            >
              <SideBySideItem event={event} evenItem={!!(index % 2)} />
            </li>
          ))}
        </ul>
        {loadMoreVisible && (
          <div className={experiments.enabled(ExperimentNames.CornerRadiusAndShadow) ? null : s.loadMoreWrapper}>
            <LoadMoreButton hasMore={hasMore} moreLoading={moreLoading} />
          </div>
        )}
      </div>
    </div>
  )
}
