import {useStyles} from '@wix/tpa-settings/react'
import {ExperimentNames, IMAGE_HOVER_EFFECT} from '@wix/wix-events-commons-statics'
import {useEnvironment, useExperiments, useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React, {useMemo, useRef, useState} from 'react'
import {isEventsInMembersInstalled} from '../../../../../../commons/selectors/installed-apps'
import stylesParams from '../../../../stylesParams'
import {useWidgetState} from '../../../hooks/state-provider'
import {useImageHoverEffect} from '../../../hooks/use-image-hover-effect'
import {useVisibilityStyles} from '../../../hooks/use-visibility-styles'
import {shouldShowCountdown} from '../../../selectors/layout'
import {isMembersVisible, isRibbonVisible} from '../../../selectors/settings'
import classesV2 from '../../classes-v2.scss'
import {Countdown} from '../../countdown'
import {EventImage} from '../../event-image'
import {FullDateLocation} from '../../full-date-location'
import {LinkToPage} from '../../link-to-page'
import {Members} from '../../members'
import {Ribbon} from '../../ribbon'
import {RsvpButton} from '../../rsvp-button'
import {ShortDateLocation} from '../../short-date-location'
import {SocialBar} from '../../social-bar'
import s from './side-by-side-item.scss'

const getImageHoverEffectClass = (imageHoverEffect: IMAGE_HOVER_EFFECT) => {
  if (imageHoverEffect === IMAGE_HOVER_EFFECT.ZOOM_IN) {
    return s.zoomInEffect
  }

  return null
}

const initialDetailsStyles = {
  scrollContainer: {},
  content: {},
}

interface SideBySideItemProps {
  event: ExtendedEvent
  evenItem: boolean
}

export const SideBySideItem = ({event, evenItem}: SideBySideItemProps) => {
  const {t} = useTranslation()
  const {isRTL} = useEnvironment()
  const {get} = useStyles()
  const {experiments} = useExperiments()

  const {effectPreviewActive, imageHoverEffect} = useImageHoverEffect()
  const imageHoverEffectClassName = useMemo(() => getImageHoverEffectClass(imageHoverEffect), [imageHoverEffect])

  const widgetHeightSettingsEnabled = experiments.enabled(ExperimentNames.WidgetHeightSettings)

  const showMembers = useWidgetState(state => isMembersVisible(state, event))
  const membersEnabled = useWidgetState(isEventsInMembersInstalled)
  const showRibbon = useWidgetState(state => isRibbonVisible(state, event))
  const showCountdown = shouldShowCountdown(event)
  const listItemLeftPadding = get(stylesParams.listItemLeftPadding)
  const listItemRightPadding = get(stylesParams.listItemRightPadding)

  const scrollableContainerRef = useRef<HTMLDivElement>(null)
  const [scrollableContainerStyles, setScrollableContainerStyles] = useState(initialDetailsStyles)

  const {
    allBreakpoints: {
      isListCountdownEnabled,
      isListShortDateLocationVisible,
      isListFullDateLocationVisible,
      isListDescriptionEnabled,
    },
  } = useVisibilityStyles()

  const handleMouseOver = () => {
    const scrollableContainerWidth = scrollableContainerRef.current.getBoundingClientRect().width

    setScrollableContainerStyles({
      scrollContainer: {
        overflow: 'auto',
      },
      content: {
        width: scrollableContainerWidth - (isRTL ? listItemLeftPadding : listItemRightPadding),
        padding: 0,
        paddingInlineStart: isRTL ? listItemRightPadding : listItemLeftPadding,
      },
    })
  }

  const handleMouseOut = () => setScrollableContainerStyles(initialDetailsStyles)

  return (
    <div
      className={classNames(s.container, evenItem ? s.even : s.odd, {
        [s.forceImageHoverEffect]: effectPreviewActive,
      })}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <div className={s.imageContainer}>
        <EventImage
          event={event}
          opacityFallback
          backgroundFallback
          className={classNames(s.image, imageHoverEffectClassName)}
        />
      </div>
      <div className={s.content}>
        <div className={classNames(s.details, {[s.widgetHeightSettings]: widgetHeightSettingsEnabled})}>
          {showRibbon && (
            <Ribbon event={event} className={classNames(s.ribbon, {[s.overrideMargin]: !showCountdown})} />
          )}
          {isListCountdownEnabled() && showCountdown && (
            <div className={s.countdown}>
              <Countdown event={event} />
            </div>
          )}
          <div
            className={s.scrollContainer}
            ref={scrollableContainerRef}
            style={scrollableContainerStyles.scrollContainer}
          >
            <div className={s.scrollContent} style={scrollableContainerStyles.content}>
              <div className={s.title}>
                <LinkToPage allowExternal event={event} dataHook="title">
                  {event.title}
                </LinkToPage>
              </div>
              {isListShortDateLocationVisible() && (
                <div className={s.shortDateLocation}>
                  <ShortDateLocation
                    event={event}
                    dateClassName={classesV2.eventDate}
                    locationClassName={classesV2.eventLocation}
                  />
                </div>
              )}
              {isListFullDateLocationVisible() && (
                <div className={s.fullDateLocation}>
                  <FullDateLocation
                    event={event}
                    dateClassName={classesV2.eventDate}
                    locationClassName={classesV2.eventLocation}
                  />
                </div>
              )}
              {!!event.description && isListDescriptionEnabled() && (
                <div className={s.description}>{event.description}</div>
              )}
              {showMembers && (
                <div className={s.members}>
                  <Members event={event} />
                </div>
              )}
              {membersEnabled && <SocialBar event={event} t={t} className={s.socialBar} />}
            </div>
          </div>
        </div>
        <div className={s.rsvpButton}>
          <RsvpButton event={event} />
        </div>
      </div>
    </div>
  )
}
