import {useStyles} from '@wix/tpa-settings/react'
import {WIDGET_TYPE} from '@wix/wix-events-commons-statics'
import React from 'react'
import stylesParams from '../../../stylesParams'
import {FeaturedSideBySide} from './featured-side-by-side'
import {SideBySideList} from './side-by-side-list'

export const SideBySide = () => {
  const {get} = useStyles()
  const featuredWidget = get(stylesParams.widgetType) === WIDGET_TYPE.FEATURED

  return featuredWidget ? <FeaturedSideBySide /> : <SideBySideList />
}
