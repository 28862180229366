import {withTranslation} from '@wix/yoshi-flow-editor'
import {getElementWidth} from '../../../../../../commons/utils/dom'
import {AppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {SideBySideListOwnProps, SideBySideListStateProps} from './interfaces'
import {SideBySideList as Presentation} from './side-by-side-list'

const mapRuntime = ({state, host: {dimensions}}: AppProps): SideBySideListStateProps => ({
  width: dimensions.width || getElementWidth(`#${state.component.id}`),
})

export const SideBySideList = connect<SideBySideListOwnProps, SideBySideListStateProps>(mapRuntime)(
  withTranslation()(Presentation),
)
