import React from 'react'
import {useWidgetState} from '../../../hooks/state-provider'
import {getEvents} from '../../../selectors/events'
import {SideBySideItem} from '../side-by-side-item'
import s from './featured-side-by-side.scss'

export const FeaturedSideBySide = () => {
  const [featuredEvent] = useWidgetState(getEvents)

  return (
    <div className={s.wrapper}>
      <div className={s.item}>
        <SideBySideItem event={featuredEvent} evenItem={false} />
      </div>
    </div>
  )
}
