import s from './side-by-side-list.scss'

const CONTAINER_WIDTHS = {
  MEDIUM: 1200,
  BIG: 1500,
  EXTRA_BIG: 1800,
}

export class Utils {
  itemsHeightClass = (containerWidth: number) => {
    if (containerWidth) {
      if (containerWidth <= CONTAINER_WIDTHS.MEDIUM) {
        return s.small
      } else if (containerWidth <= CONTAINER_WIDTHS.BIG) {
        return s.medium
      } else if (containerWidth <= CONTAINER_WIDTHS.EXTRA_BIG) {
        return s.big
      }
    }

    return s.extraBig
  }
}
